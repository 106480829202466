<template>
  <van-skeleton title :loading="loading" :row="8">
    <div id="app">

      <Head :logo="site.syslogsecond" :logo2="site.syslogmain"></Head>
      <div class="content">
        <router-view />
      </div>
      <Footer :site="site"></Footer>
    </div>
  </van-skeleton>
</template>

<script>
import { site } from '@/api/api.js'
import Head from '@/components/Head.vue'
import Footer from '@/components/Footer.vue'
import { mapState, mapMutations } from 'vuex'
export default {
  components: {
    Head,
    Footer
  },
  data() {
    return {
      site: {},
      footStatus: false,
      loading: true,
      urlParam: []
    }
  },
  async created() {
    // 如果头上携带了shareid
    if (this.$route.query.shareid) {
      sessionStorage.setItem('shareid', this.$route.query.shareid)
    }
    // 如果头上携带了activeid
    if (this.$route.query.activeid) {
      sessionStorage.setItem('activeid', this.$route.query.activeid)
    }
    if (this.$route.query.bd_vid) {
      sessionStorage.setItem('bdvid', this.$route.query.bd_vid)
    }
    this.toastLoading('加载中')
    await this.getSite()
  },
  methods: {
    ...mapMutations(['setToken']),
    async getSite() {
      const res = await site()
      this.site = res
      const url = location.href.split('?')
      // 判断是不是pc
      if (!window.navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)) {
        location.href = url[1] ? res.websitedomin + '?' + url[1] : res.websitedomin
      }
      sessionStorage.setItem('name', res.name)
      sessionStorage.setItem('imgdomin', res.imgdomin)
      sessionStorage.setItem('officialimg', res.officialimg)
      sessionStorage.setItem('qrcode', res.qrcode)
      sessionStorage.setItem('mobiledomin', res.mobiledomin)
      sessionStorage.setItem('mobiledomin', res.mobiledomin)
      sessionStorage.setItem('votedomin', res.votedomin)
      sessionStorage.setItem('syslogmain', res.syslogmain)
      sessionStorage.setItem('sysico', res.sysico)
      this.loading = false
      this.toastClose()
      const favicon = document.querySelector('link[rel="icon"]')
      favicon.href = res.sysico
    }
  },
  computed: {
    ...mapState(['name'])
  },
  watch: {
    site: {
      immediate: true,
      handler(val) {
        if (val.adress) {
          this.footStatus = true
        }
      }
    },
    $route: {
      immediate: true,
      handler() {
        window.scrollTo(0, 0)
      }
    }
  }
}
</script>

<style lang="less" scoped>
#app {
  width: 100vw;

  > div.content {
    width: 100vw;
  }
}
</style>
